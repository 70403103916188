<template>
  <div :class="['block__subtitle mb-40', classTitle]">{{ title }}</div>
  <div class="block__tabs mb-30">
    <VTabs v-if="isTabs" :is-small="true" :items="getItems "/>
  </div>
  <div class="block__control" v-if="conditionShow">
    <div class="block__field">
      <VSearch
        name="search"
        placeholder="Поиск"
        class-input="input-border-all input-padding-25 input-height-48"
        v-model="input"
      />

      <VFilter
        @onFilter="onSubmit"
        @onReset="onReset"
      >
        <template v-slot:default>
          <VDateInput
            class="mb-40"
            name="CreatedAt"
            placeholder="Дата создания"
            v-model="dateCreate"
            :is-min="false"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="StartedAt"
            placeholder="Дата начала"
            v-model="dateStart"
            :is-min="false"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="PublishedAt"
            placeholder="Дата публикации"
            v-model="datePublished"
            :is-min="false"
            @input="onDateInput"
          />
          <VDateInput
            class="mb-40"
            name="Duration"
            placeholder="Дата окончания"
            v-model="dateDuration"
            :is-min="false"
            @input="onDateInput"
          />
          <VSelect
            v-model="filterFormData.CompanyId"
            placeholder="Компания"
            name="CompanyId"
            :options="filterOptions.companies"
          />
          <VSelect
            v-model="filterFormData.ResponsibleFullName"
            placeholder="Организатор"
            name="ResponsibleFullName"
            :options="filterOptions.organizers"
          />
        </template>
      </VFilter>
    </div>
    <slot name="header"/>
  </div>

  <div v-if="isMain">
    <router-view></router-view>
  </div>
  <div>
    <slot name="route"/>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import VSearch from '@/components/ui/form/Search/VSearch'
import VDateInput from '@/components/ui/form/VDateInput'
import VFilter from '@/components/ui/modal/filter/VFilter'
import VTabs from '@/components/views/Tabs/VTabs'
import { adminTrade, organizerTrade, securityTrade, userTrade } from '@/utils/tabs'
import { useFilter } from '@/use/Base/useFilter'
import VSelect from '@/components/ui/form/VSelect.vue'
import { useTradeFilterUsers } from '@/use/trade/useTradeFilterUsers'
import { useRoute } from 'vue-router'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    tabs: {
      type: String,
      required: false,
      default: 'userTrade'
    },
    isMain: {
      type: Boolean,
      required: false,
      default: true
    },
    isTabs: {
      type: Boolean,
      required: false,
      default: true
    },
    classTitle: {
      type: String,
      required: false
    },
    conditionShow: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup (props) {
    const tabsMap = new Map([
      ['adminsTrade', adminTrade],
      ['securityTrade', securityTrade],
      ['organizerTrade', organizerTrade],
      ['userTrade', userTrade]
    ])
    const dateCreate = ref(null)
    const datePublished = ref(null)
    const dateDuration = ref(null)
    const dateStart = ref(null)
    const dateInput = []

    const onDateInput = (name, value) => {
      dateInput.push([name, value])
    }

    const route = useRoute()

    const {
      filterFormData,
      filterOptions,
      resetFilter,
      fillFilter
    } = useTradeFilterUsers(
      {
        hasArchive: true,
        hasDeleted: route.name.includes('admin')
      },
      getTradeFilterParams()
    )

    function getTradeFilterParams () {
      if (route.name.includes('organizer-my-trade')) {
        return {
          'search[MyLot]': ''
        }
      }
    }

    const filter = () => {
      const filter = []
      for (let i = 0; i < dateInput.length; i++) {
        const filterName = dateInput[i][0]
        const date = dateInput[i][1].toISOString().split('T')
        filter.push({ type: filterName, value: date[0] })
      }

      fillFilter(filter)
      return filter
    }

    const reset = () => {
      dateCreate.value = null
      datePublished.value = null
      dateDuration.value = null
      dateStart.value = null
      resetFilter()
    }

    const getItems = computed(() => {
      return tabsMap.get(props.tabs)
    })

    return {
      getItems,
      adminTrade,
      ...useFilter(filter, reset),
      onDateInput,
      dateCreate,
      datePublished,
      dateStart,
      dateDuration,
      filterFormData,
      filterOptions
    }
  },
  components: {
    VSelect,
    VDateInput,
    VSearch,
    VFilter,
    VTabs
  }
}
</script>
